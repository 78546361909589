<template>


 <div class="container anypost-container" id="top">

   <div class="row anypost-row-header">
     <h1 class="anypost-title"><router-link to="/" class="anypost-header-link">AnyPost<span class="anypost-title-domain">.dev</span></router-link></h1>
     <div class="anypost-subtitle">{{ t("message.subtitle")}}</div>
   </div>

   <div class="row">
     <div class="col-12 text-start align-items-center">
       <h3>{{ t("spec.title")}}</h3>
       {{ t("spec.lead_sentence_title")}}
       <br>
       <br>
     </div>

     <div class="col-12 text-start align-items-center">
       <ul>
         <li>{{ t("spec.title_browser")}}</li>
         <ul>
           <li><a href="#browser-bookmarklet">{{ t("spec.title_bookmarklet")}}</a></li>
         </ul>
         <li>{{ t("spec.title_site")}}</li>
         <ul>
           <li><a href="#simple-button">{{ t("spec.title_simple_button")}}</a></li>
           <li><a href="#customize-button">{{ t("spec.title_customize_button")}}</a></li>
           <li><a href="#develop-button">{{ t("spec.title_url_spec") }}</a></li>
         </ul>
         <li><a href="#supported-platform">{{ t("spec.title_supported")}}</a></li>
       </ul>
     </div>

     <div class="col-12 text-start align-items-center">
       <h4 id="browser-bookmarklet">{{ t("spec.title_bookmarklet")}}</h4>
       {{ t("spec.lead_sentence_bookmarklet")}}
       <br><br>
       {{ t("spec.code")}}:
       <textarea readonly class="form-control anypost-code">javascript:window.open('https://anypost.dev/share?s=%27+location.href+%27&t=%27+document.title, %27%27, %27width=500,height=750%27)</textarea>
     </div>

     <div class="col-12 text-start align-items-center">
       <h4 id="simple-button">{{ t("spec.title_simple_button")}}</h4>
       {{ t("spec.lead_sentence_simple_button")}}
       <br><br>
       {{ t("spec.sample")}}:
       <a href="https://anypost.dev/share"  onclick="window.open(this.href+'?s='+location.href+'&t='+document.title, '', 'width=500,height=750'); return false;" style="color: white; text-decoration-line: none; width: 91px;">
          <div style="display: inline-block; background: #000080; padding: 4px; margin: 4px 6px; border-radius: 5px; text-align: center; width: 80px; line-height: 7px">
            <img src="https://anypost.dev/external-assets/share.svg" width="14" alt="share">
            Share
            <div style="font-size: 0.5em;">via anypost.dev</div>
          </div>
        </a>
       {{ t("spec.code")}}:
       <textarea readonly class="form-control anypost-code"><a href="https://anypost.dev/share" onclick="window.open(this.href+'?s='+location.href+'&amp;t='+document.title, '', 'width=500,height=750'); return false;" style="color: white; text-decoration-line: none; width: 91px;"><div style="display: inline-block; background: rgb(0, 0, 128); padding: 4px; margin: 4px 6px; border-radius: 5px; text-align: center; width: 80px; line-height: 7px;"><img src="https://anypost.dev/external-assets/share.svg" width="14" alt="share"> Share <div style="font-size: 0.5em;">via anypost.dev</div></div></a></textarea>
     </div>

   </div>

   <div class="row">
     <div class="col-12 text-start align-items-center">
       <h4 id="customize-button">{{ t("spec.title_customize_button")}}</h4>
       {{ t("spec.lead_sentence_custom_button")}}
       <br><br>
       <CustomizeShareButton />

     </div>
   </div>

   <div class="row">
     <div class="col-12 text-start align-items-center">
       <h4 id="develop-button">{{ t("spec.title_url_spec") }}</h4>
       {{ t("spec.lead_sentence_url_spec")}}
       <br><br>
     </div>

     <div class="col-lg-6 col-12  text-start align-items-center">
       <table class="table table-striped">
         <thead>
           <tr>
             <th scope="col">{{ t("spec.query_parameter")}}</th>
             <th scope="col">{{ t("spec.value")}}</th>
           </tr>
         </thead>
         <tbody>
           <tr>
             <td>t</td>
             <td>{{ t("spec.value_text")}} </td>
           </tr>
           <tr>
             <td>s</td>
             <td>{{ t("spec.value_url")}} </td>
           </tr>
           <tr>
             <td>h</td>
             <td>{{ t("spec.value_hashtag")}} </td>
           </tr>
           <tr>
             <td>v</td>
             <td>{{ t("spec.value_visibility")}}&nbsp;{{ t("spec.notation_please_check")}}</td>
           </tr>
           <tr>
             <td>i</td>
             <td>{{ t("spec.value_instance")}} </td>
           </tr>
           <tr>
             <td>a</td>
             <td>{{ t("spec.value_redirect")}}&nbsp;{{ t("spec.notation_please_check")}}</td>
           </tr>
         </tbody>
       </table>
     </div>

     <div class="col-12 text-start align-items-center">
       <ul>
         <li>{{ t("spec.notation_option")}}</li>
         <li>{{ t("spec.notation_supported_parameter")}}</li>
         <li>{{ t("spec.notation_supported_visibility_instance")}}</li>
         <li>{{ t("spec.notation_instance")}}</li>
         <li>{{ t("spec.notation_redirect")}}</li>
       </ul>
       {{ t("spec.sample")}}:
       <textarea readonly class="anypost-code">https://anypost.dev/share?t=anypost%20can%20share%20to%20anywhere!&s=https://example.com/&v=unlisted&i=mstdn.social</textarea>
     </div>

     <div class="col-12 text-start align-items-center">
       <h4 id="supported-platform">{{ t("spec.title_supported")}}</h4>
       <ul>
         <li>Fediverse</li>
         <ul>
           <li>Mastodon</li>
           <li>Pleroma</li>
           <li>Akkoma</li>
           <li>Misskey</li>
           <li>GNU Social</li>
           <li>Diaspora</li>
           <li>Friendica</li>
         </ul>
         <li>Non-Fediverse</li>
         <ul>
            <li>Twitter (twitter.com)</li>
            <li>Facebook (facebook.com)</li>
            <li>LINE (line.me)</li>
            <li>Hatena bookmark (b.hatena.ne.jp)</li>
            <li>Pocket (getpocket.com)</li>
            <li>Pinterest (pinterest.com)</li>
            <li>LinkedIn (linkedin.com)</li>
            <li>Xing (www.xing.com)</li>
         </ul>
       </ul>
     </div>

   </div>

   <FooterText
   :appear_footer_sentence=true
   :enable_cookie=true />

 </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import CustomizeShareButton from './CustomizeShareButton.vue';
import FooterText from './FooterText.vue';
import { messages } from '@/locales/local.js';

export default {

  components: {
    CustomizeShareButton,
    FooterText,
  },

 setup() {
   const { t } = useI18n({
     legacy: false,
     locale: navigator.language.split("-")[0],
     fallbackLocale: "en",
     messages: messages,
   })
   const msg = computed(() => t('msg'))
   return { t, msg }
 },

 data() {
   return {
     locale: navigator.language.split("-")[0],
   }
 },

}

</script>

<style>

h3{
  padding-top: 0.9em;
}

h4{
  padding-top: 1.5em;
}

span.anypost-title-domain{
 font-size: 1rem;
}

div.anypost-subtitle {
 font-size: 0.8rem;
}

div.anypost-row-top-description {
 margin-top: 15px;
 margin-bottom: 15px;
}

.anypost-server {
 margin: 4px;
}

.anypost-code {
  font-size: 0.8em !important;
}

</style>
