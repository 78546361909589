<template>
 <div class="container anypost-container" id="top">

   <div class="row anypost-row-header">
     <h1 class="anypost-title"><router-link to="/" class="anypost-header-link">AnyPost<span class="anypost-title-domain">.dev</span></router-link></h1>
     <div class="anypost-subtitle">{{ t("message.subtitle")}}</div>
   </div>

   <div class="row">
     <div class="col-12 text-start align-items-center">
       <div class="anypost-row-top-description anypost-row-terms">

         {{ t("term.japanease") }}

         <h3>{{ t("term.title_terms") }}</h3>
         {{ t("term.terms.lead") }}

         <ul>
           <li>{{ t("term.terms.no_warranty") }}</li>
           <li>{{ t("term.terms.no_support") }}</li>
           <li>{{ t("term.terms.link_to_3rd_party") }}</li>
           <li>{{ t("term.terms.ban") }}</li>
         </ul>

         <h3>{{ t("term.title_privacy") }}</h3>
         {{ t("term.privacy.lead") }}

         <h5>{{ t("term.privacy.title_pii") }}</h5>
         {{ t("term.privacy.pii") }}

         <h5>{{ t("term.privacy.title_gathering") }}</h5>
         <ul>
           <li>{{ t("term.privacy.gathering.ipaddress") }}</li>
           <li>{{ t("term.privacy.gathering.metadata") }}</li>
         </ul>

         <h5>{{ t("term.privacy.title_usage") }}</h5>
         {{ t("term.privacy.usage.lead") }}
         <ul>
           <li>{{ t("term.privacy.usage.ban") }}</li>
           <li>{{ t("term.privacy.usage.inquery") }}</li>
           <li>{{ t("term.privacy.usage.other") }}</li>
         </ul>

         <h5>{{ t("term.privacy.title_party") }}</h5>
        {{ t("term.privacy.party.lead") }}
         <ul>
           <li>{{ t("term.privacy.party.life") }}</li>
           <li>{{ t("term.privacy.party.children") }}</li>
           <li>{{ t("term.privacy.party.government") }}</li>
         </ul>

         <h5>{{ t("term.privacy.title_disclose") }}</h5>
         {{ t("term.privacy.disclose.lead") }}
         <ul>
           <li>{{ t("term.privacy.disclose.life") }}</li>
           <li>{{ t("term.privacy.disclose.ban") }}</li>
           <li>{{ t("term.privacy.disclose.low") }}</li>
         </ul>
         {{ t("term.privacy.disclose.end") }}

         <h5>{{ t("term.privacy.title_delete") }}</h5>
         {{ t("term.privacy.delete.first") }}
         <br>
         {{ t("term.privacy.delete.second") }}
         <br>
         {{ t("term.privacy.delete.third") }}

         <h5>{{ t("term.privacy.title_cookie") }}</h5>
         {{ t("term.privacy.cookie") }}
         <ul>
           <li>{{ t("term.privacy.cookie_list.language_settings") }}</li>
           <li>{{ t("term.privacy.cookie_list.server_settings") }}</li>
           <li>{{ t("term.privacy.cookie_list.ga") }}</li>
         </ul>

         <h5>{{ t("term.privacy.title_webservice") }}</h5>
         {{ t("term.privacy.webservice.lead") }}
         <ul>
           <li>{{ t("term.privacy.webservice.ga") }}</li>
         </ul>

         <h5>{{ t("term.privacy.title_change") }}</h5>
         {{ t("term.privacy.change") }}

         <h5>{{ t("term.privacy.title_inquery") }}</h5>
         {{ t("term.privacy.inquery") }}

         <h3>{{ t("term.title_copyrights") }}</h3>
         {{ t("term.copyrights.header") }}
         <ul>
           <li>{{ t("term.copyrights.logo") }} </li>
             <ul>
               <li> {{ t("term.copyrights.logo_misskey") }} &nbsp;&nbsp; <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed">CC BY-NC-SA 4.0</a></li>
               <li> {{ t("term.copyrights.logo_gnusocial") }} &nbsp;&nbsp; <a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a></li>
             </ul>
         </ul>

         <h3>{{ t("term.title_contacts") }}</h3>
         {{ t("term.contacts.header") }}
         <ul>
           <li><a href="https://forms.gle/WbLTKqeaztVbAfJ27" target="_blank">{{ t("term.contacts.law_rights") }}</a></li>
           <li><a href="https://highemerly.net/#contact" target="_blank">{{ t("term.contacts.other") }}</a></li>
         </ul>
         <br>
         {{ t("term.japanease") }}

       </div>
     </div>
   </div>

   <FooterText
   :appear_footer_sentence=false
   :enable_cookie=false />

 </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import FooterText from './FooterText.vue';
import { messages } from '@/locales/local.js';

export default {

 components: {
   FooterText,
 },

 setup() {
   const { t } = useI18n({
     legacy: false,
     locale: navigator.language.split("-")[0],
     fallbackLocale: "en",
     messages: messages,
   })
   const msg = computed(() => t('msg'))
   return { t, msg }
 },

 data() {
   return {
     locale: navigator.language.split("-")[0],
   }
 },

}

</script>

<style>

.anypost-row-terms > ul{
  margin-top: 3px;
  margin-bottom: 3px;
}

.anypost-row-terms > h5{
  margin-top: 16px;
}

</style>
