import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'
import App from './App.vue'
import i18n from './i18n'
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'
import router from './route/index'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/main.css"

createApp(App)
 .use(router)
 .use(i18n)
 .use(VueCookieComply)
 .use(createGtm({
   id: 'GTM-TGB8CW2',
   enabled: false,
   debug: false,
   vueRouter: router,
   loadScript: true,
   ignoredViews: [''],
 }))
 .mount('#app')
