var GETPARAMS_INSTANCE   = 'i'
var GETPARAMS_TEXT       = 't'
var GETPARAMS_URL        = 's'
var GETPARAMS_VISIBILITY = 'v'
var GETPARAMS_REPLYID    = 'r'
var GETPARAMS_HASHTAG    = 'h'
var GETPARAMS_REDIRECT   = 'a'

var getUrlVars = function(){
    var vars = {};
    var param = location.search.substring(1).split('&');
    for(var i = 0; i < param.length; i++) {
        var keySearch = param[i].search(/=/);
        var key = '';
        if(keySearch != -1) key = param[i].slice(0, keySearch);
        var val = param[i].slice(param[i].indexOf('=', 0) + 1);
        if(key != '') vars[key] = decodeURIComponent(val);
    }
    return vars;
}

export var getRequest = function() {
  var req_params = getUrlVars()
  var req = {}

  req['instance_name'] = (GETPARAMS_INSTANCE in req_params) ? req_params[GETPARAMS_INSTANCE].replace(/https?:\/\//, "").replace(/\//, "") : ""
  req['text']          = (GETPARAMS_TEXT in req_params) ? req_params[GETPARAMS_TEXT] : ""
  req['url']           = (GETPARAMS_URL in req_params) ? req_params[GETPARAMS_URL] : ""
  req['visibility']    = (GETPARAMS_VISIBILITY in req_params) ? req_params[GETPARAMS_VISIBILITY] : "public"
  req['reply_id']      = (GETPARAMS_REPLYID in req_params) ? req_params[GETPARAMS_REPLYID] : ""
  req['hashtag']       = (GETPARAMS_HASHTAG in req_params) ? req_params[GETPARAMS_HASHTAG] : ""
  req['redirect']      = (GETPARAMS_REDIRECT in req_params)&&(GETPARAMS_INSTANCE in req_params) ? true : false

  return req
}
