<template>

{{ this.$parent.t("spec.sample") }}:

 <div :style="{background: color, width: width + 'px', height: height + 'px', padding: padding + 'px'}" style="display: inline-block; margin: 4px 6px; border-radius: 5px; text-align: center; line-height: 7px;">
   <a href="https://anypost.dev/share"  :onclick="getOnClickJavascript()" :style="{color: button_strings_color}" style="text-decoration-line: none;">
     {{img_src_tag}}
     <div :style="{fontSize: font_size + 'px'}" style="display: flex; align-items: center; justify-content: center; ">
       <img :src=button_icon_url :width=icon_size alt="share" style="padding-right: 4px" v-if="button_icon_boolean">
       {{button_strings_big}}
     </div>
     <div :style="{fontSize: font_size/2 + 'px'}" style="display: flex; align-items: center; justify-content: center; padding-top: 4px">
       {{button_strings_small}}
     </div>
   </a>
 </div>

 {{ this.$parent.t("spec.code") }}:

<textarea class="form-control" v-model="code" style="font-size: 0.8em; height: 9rem;"></textarea>

 <div class="row">

   <div class="col-12">
     <div class="anypost-customize-share-button-h6">
       1. {{ this.$parent.t("spec.customize_appearance_description") }}
     </div>
   </div>

   <div class="col-lg-6 col-12">
    <label class="form-label" style="margin-top: 10px">{{ this.$parent.t("spec.customize_appearance.button_strings")}}:</label>
    <input class="form-control form-control-lg" v-model="button_strings_big" />
   </div>

   <div class="col-lg-6 col-12">
     <label class="form-label" style="margin-top: 10px">{{ this.$parent.t("spec.customize_appearance.button_strings_small")}}:</label>
     <input class="form-control form-control-lg" v-model="button_strings_small" />
   </div>

   <div class="col-12">
     <div class="row align-items-center" style="margin-top: 15px">
       <div class="col-auto">
         <label class="form-label" style="margin-bottom: 0" >{{ this.$parent.t("spec.customize_icon") }}:</label>
       </div>
       <div class="col-auto v-model-radiobutton">
         <input type="radio" class="form-check-input" value="none" id="none" v-model="button_icon" />
         <label class="form-label" for="None" style="margin-bottom: 0" >{{ this.$parent.t("spec.customize_icon_none") }}</label>
       </div>
       <div class="col-auto v-model-radiobutton">
         <input type="radio" class="form-check-input" value="share" id="share" v-model="button_icon" />
         <label class="form-label" for="share" style="margin-bottom: 0" :style="{background: color}"><img src="https://anypost.dev/external-assets/share.svg" width="30" alt="share"></label>
       </div>
       <div class="col-auto v-model-radiobutton">
         <input type="radio" class="form-check-input" value="share-b" id="share-b" v-model="button_icon" />
         <label class="form-label" for="share-b" style="margin-bottom: 0" :style="{background: color}"><img src="https://anypost.dev/external-assets/share-b.svg" width="30" alt="share"></label>
       </div>
       <div class="col-auto v-model-radiobutton">
         <input type="radio" class="form-check-input" value="good" id="good" v-model="button_icon" />
         <label class="form-label" for="good" style="margin-bottom: 0" :style="{background: color}"><img src="https://anypost.dev/external-assets/good.svg" width="30" alt="good"></label>
       </div>
       <div class="col-auto v-model-radiobutton">
         <input type="radio" class="form-check-input" value="good-b" id="good-b" v-model="button_icon" />
         <label class="form-label" for="good-b" style="margin-bottom: 0" :style="{background: color}"><img src="https://anypost.dev/external-assets/good-b.svg" width="30" alt="good"></label>
       </div>
<!--
       <div class="col-auto v-model-radiobutton">
         <input type="radio" class="form-check-input" value="mastodon" id="mastodon" v-model="button_icon" />
         <label class="form-label" for="mastodon" style="margin-bottom: 0" :style="{background: color}"><img src="https://anypost.dev/external-assets/mastodon.svg" width="30" alt="mastodon"></label>
       </div>
-->

     </div>
   </div>

   <div class="col-12">
     <div class="anypost-customize-share-button-h6">
       2. {{ this.$parent.t("spec.customize_appearance_detail_description") }}
     </div>
   </div>

   <div class="col-lg-4 col-12">
     <div class="row align-items-center">
       <div class="col-auto">
         <label class="form-label" style="margin-bottom: 0">{{ this.$parent.t("spec.customize_appearance.font_size")}}:</label>
       </div>
       <div class="col-auto" style="width: 6rem">
         <input class="form-control form-control" type="number" v-model.trim="font_size" />
       </div>
       <div class="col-auto">
         <span class="form-text">pt</span>
       </div>
     </div>
   </div>

   <div class="col-lg-4 col-md-6 col-12">
     <div class="row align-items-center">
       <div class="col-auto">
         <label class="form-label" style="margin-bottom: 0">{{ this.$parent.t("spec.customize_appearance.width")}}:</label>
       </div>
       <div class="col-auto" style="width: 6rem">
         <input class="form-control form-control" type="number" v-model.trim="width" />
       </div>
       <div class="col-auto">
         <span class="form-text">px</span>
       </div>
     </div>
   </div>

   <div class="col-lg-4 col-md-6 col-12">
     <div class="row align-items-center">
       <div class="col-auto">
         <label class="form-label" style="margin-bottom: 0">{{ this.$parent.t("spec.customize_appearance.height")}}:</label>
       </div>
       <div class="col-auto" style="width: 6rem">
         <input class="form-control form-control" type="number" v-model.trim="height" />
       </div>
       <div class="col-auto">
         <span class="form-text">px</span>
       </div>
     </div>
   </div>

   <div class="col-lg-4 col-md-6 col-12">
     <div class="row align-items-center">
       <div class="col-auto">
         <label class="form-label" style="margin-bottom: 0">{{ this.$parent.t("spec.customize_appearance.image_size")}}:</label>
       </div>
       <div class="col-auto" style="width: 6rem">
         <input class="form-control form-control" type="number" v-model.trim="icon_size" />
       </div>
       <div class="col-auto">
         <span class="form-text">px</span>
       </div>
     </div>
   </div>

   <div class="col-lg-4 col-md-6 col-12">
     <div class="row align-items-center">
       <div class="col-auto">
         <label class="form-label" style="margin-bottom: 0">{{ this.$parent.t("spec.customize_appearance.padding")}}:</label>
       </div>
       <div class="col-auto" style="width: 6rem">
         <input class="form-control form-control" type="number" v-model.trim="padding" />
       </div>
       <div class="col-auto">
         <span class="form-text">px</span>
       </div>
     </div>
   </div>

</div>
<div class="row">

   <div class="col-lg-4 col-md-6 col-12">
     <div class="row align-items-center">
       <div class="col-auto">
         <label class="form-label">{{ this.$parent.t("spec.customize_appearance.font_color") }}:</label>
       </div>
       <div class="col-auto">
         <ColorPicker
           theme="light"
           :color="button_strings_color"
           :sucker-hide="true"
           @changeColor="changeStringsColor"
         />
       </div>
     </div>
   </div>

   <div class="col-lg-4 col-md-6 col-12">
     <div class="row align-items-center">
       <div class="col-auto">
         <label class="form-label">{{ this.$parent.t("spec.customize_appearance.color") }}:</label>
       </div>
       <div class="col-auto">
         <ColorPicker
           theme="light"
           :color="color"
           :sucker-hide="true"
           @changeColor="changeColor"
         />
       </div>
     </div>
   </div>

   <div class="col-12">
     <div class="anypost-customize-share-button-h6">
       3. {{ this.$parent.t("spec.customize_action_description") }}
     </div>
   </div>

   <div class="col-lg-6 col-12">
     <label class="form-label" style="margin-top: 10px">{{ this.$parent.t("spec.customize_post_contents") }}:</label>
     <select class="form-control form-select" v-model="action">
       <option value="url"> {{ this.$parent.t("spec.customize_action.url") }} </option>
       <option value="url_title" selected> {{ this.$parent.t("spec.customize_action.url_title") }} </option>
       <option value="url_title_strings"> {{ this.$parent.t("spec.customize_action.url_title_strings") }} </option>
       <option value="strings"> {{ this.$parent.t("spec.customize_action.strings") }} </option>
     </select>
   </div>

   <div class="col-lg-6 col-12">
     <label class="form-label" style="margin-top: 10px" v-if="action_strings_required" >{{ this.$parent.t("spec.customize_fixed_strings") }}:</label>
     <input class="form-control" v-model="action_strings" v-if="action_strings_required" />
   </div>

   <div class="col-lg-6 col-12">
     <label class="form-label" style="margin-top: 10px">{{ this.$parent.t("spec.customize_default_instance_is_specified") }}:</label>
     <select class="form-control form-select" v-model="default_instance_boolean">
       <option value="no" selected>{{ this.$parent.t("spec.customize_action.default_instance_not_specified") }}</option>
       <option value="yes">{{ this.$parent.t("spec.customize_action.default_instance_specified") }}</option>
     </select>
   </div>

   <div class="col-lg-6 col-12">
     <label class="form-label" style="margin-top: 10px" v-if="default_instance_required" >{{ this.$parent.t("spec.customize_default_instance") }}:</label>
     <input class="form-control" v-model="default_instance" v-if="default_instance_required" />
   </div>

 </div>

</template>

<script>
import { ColorPicker } from "vue-color-kit";
export default {

  components: {
    ColorPicker,
  },

 data() {

   return {
     button_strings_big: "Share",
     button_strings_small: "to fediverse",
     button_strings_color: "#ffffff",
     button_icon: "good",
     button_icon_boolean: true,
     button_icon_url: "https://anypost.dev/external-assets/good.svg",
     font_size: 25,
     width: 130,
     height: 45,
     padding: 6,
     icon_size: 25,
     color: "#357AFF",
     action: "url_title",
     action_strings_required: false,
     action_strings: "#anypost",
     default_instance_boolean: "no",
     default_instance_required: false,
     default_instance: "",
     suckerCanvas: null,
     suckerArea: [],
     code: "Code will appear here.",
     isSucking: false,
   }
 },

 updated() {
   if (this.button_icon == "none") {
     this.button_icon_url = ""
     this.button_icon_boolean = false
   }
   else {
     this.button_icon_url = "https://anypost.dev/external-assets/" + this.button_icon + ".svg"
     this.button_icon_boolean = true
   }

   switch (this.action){
   case "url":
     this.action_strings_required = false
     break;
   case "url_title":
     this.action_strings_required = false
     break;
   case "url_title_strings":
     this.action_strings_required = true
     break;
   case "strings":
       this.action_strings_required = true
       break;
   default:
     this.action_strings_required = false
     console.log("Error: unknown action")
     break;
   }

   switch (this.default_instance_boolean){
   case "yes":
     this.default_instance_required = true
     break;
   default:
     this.default_instance_required = false
     break;
   }

   if (this.button_strings_big.search(/<.+>/) >= 0 ){
     this.button_strings_big = "Share"
   }
   else if (this.button_strings_small.search(/<.+>/) >= 0 ){
     this.button_strings_small = "via anypost.dev"
   }

   this.code = this.getCustomizeCode()
 },

 methods: {
   changeColor(color) {
     const { r, g, b, a } = color.rgba;
     this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
   },
   changeStringsColor(color) {
     const { r, g, b, a } = color.rgba;
     this.button_strings_color = `rgba(${r}, ${g}, ${b}, ${a})`;
   },
   getOnClickJavascript() {
     var onclick = 'window.open(this.href+'
     var strings = this.action_strings.replace('#', '%23').replace('*', '%2A')

     switch (this.action){
     case "url":
       onclick = onclick + '\'?s=\'+location.href'
       break;
     case "url_title":
       onclick = onclick + '\'?s=\'+location.href+\'&t=\'+document.title'
       break;
     case "url_title_strings":
       onclick = onclick + '\'?s=\'+location.href+\'&t=\'+document.title+\'%20'+strings+ '\''
       break;
     case "strings":
       onclick = onclick + '\'?t='+strings+'\''
       break;
     }
     if (this.default_instance_boolean === "yes"){
       onclick = onclick + '+\'&i='+this.default_instance+'\''
     }

     onclick = onclick + ', \'\', \'width=500,height=750\'); return false;'
     return onclick
   },
   getCustomizeCode() {
     var onclick = this.getOnClickJavascript()

     var img_tag = '<img src="https://anypost.dev/external-assets/' + this.button_icon + '.svg" width="'+ this.icon_size+'" style="padding-right: 4px" alt="share">'
     if (this.button_icon === "none"){
       img_tag = ''
     }

     var font_size_small = this.font_size/2

     return '<div style="background: '+this.color+'; width: '+this.width+'px; height: '+this.height+'px; display: inline-block; padding: '+this.padding+'px; margin: 4px 6px; border-radius: 5px; text-align: center; line-height: 7px; font-family: Avenir, Helvetica,Arial,sans-serif;"><a href="https://anypost.dev/share" onclick="'+onclick+'" style="color: '+this.button_strings_color+'; text-decoration-line: none;"><div style="font-size: '+this.font_size+'px; display: flex; align-items: center; justify-content: center;">'+img_tag+this.button_strings_big+'</div><div style="font-size: '+font_size_small+'px; display: flex; align-items: center; justify-content: center; padding-top: 4px">'+this.button_strings_small+'</div></a></div>'
   }
 },

}

</script>

<style>

@import 'vue-color-kit/dist/vue-color-kit.css';

.hu-color-picker {
  box-sizing: content-box;
}

.anypost-customize-share-button-h6 {
    margin: 1.2rem 0 0.5rem 0;
    text-decoration: underline;
}

.anypost-custmize-share-button-list {
  margin-left: 1rem;
}

</style>
