<template>

  <div class="mymodal" v-if="show">
		<div class="mymodal__box">
			<span class="mymodal__message"> {{msg}} </span>
			<div class="mymodal__action">
        <div class="mymodal__btn mymodal__btn--stop" @click="onClickStopButton" v-if="show_stop_button"> {{msg_stop}} </div>
				<div class="mymodal__btn mymodal__btn--success" @click="onClickDismissButton"> {{msg_close}} </div>
			</div>
		</div>
	</div>

</template>

<script>

import { ref } from 'vue'

export default {
  name: 'ModalControl',
  props: {
    msg: String,
    msg_close: String,
    msg_stop: String,
    show_stop_button: Boolean,
  },
  setup() {
    const show = ref(false)
    return { show }
  },
  methods: {
    showModal(){
        this.show = true;
        setTimeout(() => {
          this.show = false
        }, 2500)
    },
    onClickDismissButton(){
      this.show = false;
    },
    onClickStopButton(){
      var current_url = location.href
      var redirect_url = current_url.replace(/(&?a=[0-9])/, "")
      window.location.href = redirect_url
    }
  }
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.mymodal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
}

.mymodal__box {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 80%;
	height: 200px;
	padding: 32px;
	background-color: #fff;
	border-radius: 4px;
	transform: translate(-50%, -50%);
}

.mymodal__message {
	font-size: 24px;
}

.mymodal__action {
	display: flex;
	justify-content: flex-end;
}

.mymodal__btn {
	width: 130px;
	margin-left: 16px;
	padding: 8px;
	line-height: 1.5;
	font-weight: bold;
	border: 1px solid rgb(196, 196, 196);
	cursor: pointer;
}

.mymodal__btn--success {
	color: #fff;
	border: none;
	background-color: rgb(60, 140, 245);
}

.mymodal__btn--stop {
	color: #fff;
	border: none;
	background-color: rgb(220, 20, 60);
}

</style>
