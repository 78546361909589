<template>

<div class="row anypost-space"></div>

<div class="row anypost-footer-sentence" v-if="appear_footer_sentence">
  <div class='col-lg-2'></div>
  <div class='col-lg-8 col-12'>
    {{ this.$parent.t("message.about_detail") }}
    &nbsp;&nbsp;
    <router-link to="/" class="anypost-important-link"> >> {{ this.$parent.t("message.about_learn_more") }}</router-link>
  </div>
</div>

<div class="row anypost-row-detail-settings">
  <div class="col-lg-2"></div>
  <div class='col-lg-3 col-12 anypost-footer-links'>
    <label for="locale">Language:&nbsp;</label>
    <select v-model="this.$parent.locale" v-on:change="onswitchLang">
      <option value="en">English</option>
      <option value="ja">日本語</option>
    </select>
  </div>
  <div class='col-lg-5 col-12 anypost-footer-links'>
    <router-link to="/" class="anypost-link">{{ this.$parent.t("link.top") }}</router-link>
    &nbsp;&nbsp;
    <router-link to="/share" class="anypost-link">{{ this.$parent.t("link.share") }}</router-link>
    &nbsp;&nbsp;
    <router-link to="/howto" class="anypost-link">{{ this.$parent.t("link.how_to_use") }}</router-link>
    &nbsp;&nbsp;
    <router-link to="/terms" class="anypost-link">{{ this.$parent.t("link.privacy_policy") }}</router-link>
    &nbsp;&nbsp;

    <a href="" class="anypost-link" @click="onClearSettings"> {{ this.$parent.t("message.clear_settings") }} </a>

    <vue-cookie-comply
    :preferences="gdpr_preferences_set"
    :headerTitle='this.$parent.t("message.gdpr.header")'
    :acceptAllLabel='this.$parent.t("message.gdpr.header_accept")'
    :preferencesLabel='this.$parent.t("message.gdpr.header_preference")'
    :headerDescription='this.$parent.t("message.gdpr.header_message")'
    @on-accept-all-cookies="onAcceptAll"
    @on-save-cookie-preferences="onSavePreferences"
    >
      <template v-slot:modal-header>
        <h3> {{this.$parent.t("message.gdpr.message")}} </h3>
        <h4> {{this.$parent.t("message.gdpr.message_pii_notice")}} </h4>
      </template>
    </vue-cookie-comply>

  </div>
</div>

</template>

<script>

import { ref } from 'vue'
import { useCookies } from "vue3-cookies"
import { globalCookiesConfig } from "vue3-cookies";

const COOKIE_ACCEPT_COKKIE = 'ac'
const COOKIE_ACCEPT_GOOGLE_ANALYTICS = 'ag'
const COOKIE_INSTANCE_LIST = 's1'
const COOKIE_INSTANCE_LAST_USED = 's2'
const COOKIE_UI_LANG = 'la'

globalCookiesConfig({
  expireTimes: "90d",
  path: "/"
})

export default {
  name: 'FooterText',

  props: {
    appear_footer_sentence: Boolean,
    enable_cookie: Boolean,
    msg_description: String,
    msg_accept: String,
    msg_preference_message: String,
    msg_preference: String,
    msg_clear_settings: String,
  },

  setup() {
    const { cookies } = useCookies();
    const gdpr_preferences_set = []
    const accept_cookies = ref(false)
    const candidate_instance_list = ref([])
    const instance_last_used = ref("")
    const locale = ref("")

    if (localStorage.getItem('cookie-comply') == '["cookie"]') {
      if (Date.now() - localStorage.getItem('cookie-comply-time') > 2592000000) {
        localStorage.clear()
      }
    } else
      if(localStorage.getItem('cookie-comply') == '[]' || localStorage.getItem('cookie-comply') == '["ga"]') {
        if (Date.now() - localStorage.getItem('cookie-comply-time') > 86400000) {
          localStorage.clear()
        }
      }

    if (navigator.cookieEnabled && cookies.isKey(COOKIE_ACCEPT_COKKIE)) {
      accept_cookies.value = true
      if (cookies.isKey(COOKIE_INSTANCE_LIST)) {
        candidate_instance_list.value =  cookies.get(COOKIE_INSTANCE_LIST).split(',')
      }
      if (cookies.isKey(COOKIE_INSTANCE_LAST_USED)) {
        instance_last_used.value = cookies.get(COOKIE_INSTANCE_LAST_USED)
      }
      if (cookies.isKey(COOKIE_UI_LANG)) {
        locale.value = cookies.get(COOKIE_UI_LANG)
      }
    }

    const accept_ga = ref(false)

    if (navigator.cookieEnabled && cookies.isKey(COOKIE_ACCEPT_GOOGLE_ANALYTICS)) {
      accept_ga.value = true
    }

    return {cookies, gdpr_preferences_set, accept_cookies, candidate_instance_list, instance_last_used, locale, accept_ga}
  },
  created() {
    this.gdpr_preferences_set = [
      {
        title: this.$parent.t("message.gdpr.title_basic"),
        description: this.$parent.t("message.gdpr.description_basic"),
        items: [{ label: this.$parent.t("message.gdpr.cookie"), value: 'cookie', isEnable: true}],
      },
      {
        title: this.$parent.t("message.gdpr.title_additional"),
        description: this.$parent.t("message.gdpr.description_additional"),
        items: [{ label: this.$parent.t("message.gdpr.ga"), value: 'ga' , isEnable: true}],
      },
    ] 
  },
  mounted() {
    if ( typeof this.$parent.candidate_instance !== "undefined" ){
      this.$parent.candidate_instance = this.$parent.candidate_instance.concat(this.candidate_instance_list)
      //console.log(this.$parent.is_instance_not_specified)
      if (this.instance_last_used != "" && this.$parent.is_instance_not_specified) {
        this.$parent.instance_name = this.instance_last_used
      }
    }

    if (this.accept_ga) {
      this.$gtm.enable(true)
    }
    else {
      this.$gtm.enable(false)
    }

    if (this.locale != "") {
      this.$parent.locale = this.locale
      this.$i18n.locale = this.locale
    }
  },
  methods: {
    onAcceptAll() {
      this.accept_cookies = true
      this.cookies.set(COOKIE_ACCEPT_COKKIE, '1')
      this.accept_ga = true
      this.$gtm.enable(true)
      this.cookies.set(COOKIE_ACCEPT_GOOGLE_ANALYTICS, '1')
      localStorage.setItem('cookie-comply-time', Date.now())
    },
    onSavePreferences(preferences) {
      if (preferences.includes('cookie')) {
        this.accept_cookies = true
        this.cookies.set(COOKIE_ACCEPT_COKKIE, '1')
      }
      if (preferences.includes('ga')) {
        this.accept_ga = true
        this.$gtm.enable(true)
        this.cookies.set(COOKIE_ACCEPT_GOOGLE_ANALYTICS, '1')
      }
      localStorage.setItem('cookie-comply-time', Date.now())
    },
    onClearSettings() {
      localStorage.clear()
      this.$gtm.enable(false)
      this.cookies.remove(COOKIE_ACCEPT_COKKIE)
      this.cookies.remove(COOKIE_ACCEPT_GOOGLE_ANALYTICS)
      this.cookies.remove(COOKIE_INSTANCE_LIST)
      this.cookies.remove(COOKIE_INSTANCE_LAST_USED)
      this.cookies.remove(COOKIE_UI_LANG)
    },
    onAddInstanceCandidate() {
      if (this.accept_cookies){
        if (this.cookies.isKey(COOKIE_INSTANCE_LIST)) {
          var instance_list = this.cookies.get(COOKIE_INSTANCE_LIST).split(',')
          if (instance_list.length > 9) {
            instance_list = instance_list.slice(1)
          }
          var instance_list_for_cookie = ""
          for(var i=0;i<instance_list.length;i++){
            instance_list_for_cookie = instance_list_for_cookie + instance_list[i] + ","
          }
          instance_list_for_cookie = instance_list_for_cookie + this.$parent.additional_instance
          this.cookies.set(COOKIE_INSTANCE_LIST, instance_list_for_cookie)
        }
        else {
          this.cookies.set(COOKIE_INSTANCE_LIST, this.$parent.additional_instance)
        }
      }
    },
    onClickShareButton() {
      if (this.accept_cookies) {
        this.cookies.set(COOKIE_INSTANCE_LAST_USED, this.$parent.instance_name)
      }
    },
    onswitchLang() {
      this.$i18n.locale = this.$parent.locale;
      if (this.accept_cookies){
        this.cookies.set(COOKIE_UI_LANG, this.$parent.locale)
      }
    },
  }
}

</script>

<style>

div.anypost-footer-sentence {
  margin-top: 25px;
  margin-bottom: 20px;
}

div.anypost-footer-links {
  margin-top: 8px;
}

.cookie-comply__modal-header > h3 {
  margin-top: 10px;
  font-size: 1.0em;
}
.cookie-comply__modal-header > h4 {
  font-size: 1.0em;
  padding-top: 0;
  font-weight: normal;
}

.cookie-comply__modal-switches > h3 {
  font-size: 1em;
}

.cookie-comply__modal-content > div > h2 {
  margin-top: 18px;
  font-size: 1.2em;
}

.cookie-comply__modal-content > div > p {
  margin-bottom: 0;
}

</style>
