<template>

<div class="container anypost-container" id="share">
  <div class="row anypost-row-header">
    <div class='col-lg-2'></div>
    <div class="col-lg-8 col-12">
      <div class="col-12 anypost-header">
        <h1 class="anypost-title"><router-link to="/" class="anypost-header-link">AnyPost<span class="anypost-title-domain">.dev</span></router-link></h1>
        <div class="anypost-subtitle">{{ t("message.subtitle")}}</div>
        <br />
        <button class="col-12 anypost-post-button btn btn-primary" @click="onClickShareButton">
          {{ t("message.post") }}</button>
      </div>
    </div>
  </div>
  <div class="row anypost-row-type">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-4 text-start align-items-center">
      {{ t("message.server_type") }}:
    </div>
    <div class="col-6 col-8 text-start">
      <span>
        <img :src="require(`@/${instance_type.icon}`)" class="anypost-server-brandlogo" width="28"/>
      </span>
      ( {{instance_type.name}} )
    </div>
  </div>
  <div class="row anypost-row-server">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-4 text-start align-items-center">
      {{ t("message.server") }}:
    </div>
    <div class="col-lg-6 col-8">
      <select class="col-12 form-select" v-model="instance_name">
        <option v-for="(item, index) in candidate_instance" :key=index> {{item}} </option>
      </select>
    </div>
  </div>
  <div class="row anypost-row-additional-server">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-4 text-start align-items-center" style="font-size: 0.8rem;">
      {{ t("message.server_not_listed") }}
    </div>
    <div class="col-lg-5 col-5">
      <input class="col-12 anypost-input-additional-instance" v-model.trim="additional_instance" />
    </div>
    <div class="col-lg-1 col-3">
      <button class="col-12 anypost-button-add-instance btn btn-secondary" @click="addButton">
        <LoadingText
        :default_text='t("message.server_add")'
        :loading='processing'
        />
      </button>
    </div>
  </div>
  <div class="row anypost-space"></div>
  <div class="row anypost-row-share-box">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-3 text-start align-items-center" v-show="instance_type.availableParams.includes('text')">
      {{ t("message.post_text") }}:
    </div>
    <div class="col-lg-6 col-9" v-show="instance_type.availableParams.includes('text')">
      <textarea class="col-12" v-model="text"></textarea>
    </div>
  </div>
  <div class="row anypost-row-share-box">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-3 text-start align-items-center" v-show="instance_type.availableParams.includes('url')">
      {{ t("message.post_url") }}:
    </div>
    <div class="col-lg-6 col-9" v-show="instance_type.availableParams.includes('url')">
      <input class="col-12" v-model="url" />
    </div>
  </div>
  <div class="row anypost-row-share-box">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-3 text-start align-items-center" v-show="instance_type.availableParams.includes('hashtag')">
      {{ t("message.post_hashtag") }}:
    </div>
    <div class="col-lg-6 col-9" v-show="instance_type.availableParams.includes('hashtag')">
      <input class="col-12" v-model="hashtag" />
    </div>
  </div>
  <div class="row anypost-row-share-box">
    <div class="col-lg-2"></div>
    <div class="col-lg-2 col-3 text-start align-items-center" v-show="instance_type.availableParams.includes('visibility')">
      {{t("message.post_visibility") }}:
    </div>
    <div class="col-lg-6 col-9" v-show="instance_type.availableParams.includes('visibility')">
      <select class="col-12 form-select" v-model="visibility">
        <option v-for="(item, index) in instance_type.availableVisibility" :key=index> {{item}} </option>
      </select>
    </div>
  </div>

  <FooterText
  :appear_footer_sentence=true
  :enable_cookie=true
  ref="cookieControl" />

  <ModalControl
  :msg='t("message.redirect")'
  :msg_close='t("message.modal_close")'
  :show_stop_button=true
  :msg_stop='t("message.redirect_stop")'
  ref="redirectModal"
  />
  <ModalControl
  :msg='t("message.not_supported_type")'
  :msg_close='t("message.modal_close")'
  :show_stop_button=false
  ref="notSupportedModal"
  />
    <a :href=getLink style="display: none;">{{instance_name}}</a>
</div>

</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { messages } from '@/locales/local.js';
import FooterText from './FooterText.vue';
import { getRequest } from './shareapp/getRequest.js';
import  * as instanceType  from './shareapp/getInstanceType.js';
import ModalControl from './ModalControl.vue';
import LoadingText from './LoadingText.vue';

var DEFAULT_INSTANCE_LIST = [
  "pawoo.net",
  "mastodon.cloud",
  "blob.cat",
  "misskey.io",
  "twitter.com",
  "facebook.com",
]
var candidate_instance_list = DEFAULT_INSTANCE_LIST

export default {

  components: {
    FooterText,
    ModalControl,
    LoadingText
  },

  setup() {
    const { t } = useI18n({
      legacy: false,
      locale: navigator.language.split("-")[0],
      fallbackLocale: "en",
      messages: messages,
    })
    const msg = computed(() => t('msg'))
    return { t, msg }
  },

  data() {

    var req = getRequest()
    var is_instance_not_specified = true
    if (req['instance_name'] != "" ) {
      is_instance_not_specified = false
      candidate_instance_list.push(req['instance_name'])
      candidate_instance_list = Array.from(new Set(candidate_instance_list))
    }
    else {
      req['instance_name'] = "mastodon.cloud"
    }

    return {
      candidate_instance: candidate_instance_list,
      additional_instance: "",
      instance_name: req['instance_name'],
      instance_type: instanceType.getInstanceInfo(req['instance_name']),
      visibility: req['visibility'],
      url: req['url'],
      text: req['text'],
      hashtag: req['hashtag'],
      auto_redirect: req['redirect'],
      is_instance_not_specified: is_instance_not_specified,
      locale: navigator.language.split("-")[0],
      processing: false,
    }
  },
  mounted() {
    if (this.auto_redirect && this.instance_type.name != 'unknown') {
      var redirect = this.instance_type.setQueryParam(this)
      this.$refs.redirectModal.showModal()
      setTimeout(function() { location.href = redirect }, 3 * 1000)
    }
    if (this.instance_type.name == 'unknown') {
      this.$refs.notSupportedModal.showModal()
    }
  },
  methods: {
    window:onload = function() {

    },
    addButton() {
      this.processing = true
      setTimeout(() => {
        this.addInstanceCandidate()
        this.processing = false
      }, 5)
    },
    addInstanceCandidate(){
      var requested_instance_name = this.additional_instance.replace(/https?:\/\//, "").replace(/\//, "")
      var requested_instance_type = instanceType.getInstanceInfo(requested_instance_name).name

      if (requested_instance_type != "unknown") {
        this.instance_name = requested_instance_name

        if (!this.candidate_instance.includes(requested_instance_name)) {
          this.candidate_instance.push(requested_instance_name)
          this.$refs.cookieControl.onAddInstanceCandidate()
        }
      }
      else {
        this.$refs.notSupportedModal.showModal()
      }
    },
    updateInstanceType() {
      this.instance_type = instanceType.getInstanceInfo(this.instance_name)
    },
    onClickShareButton() {
      this.$refs.cookieControl.onClickShareButton()
      if (this.instance_type.name != "unknown") {
        window.location.href = this.instance_type.setQueryParam(this)
      }
    },
  },
  computed: {
    getLink() {
      this.updateInstanceType()
      return this.instance_type.setQueryParam(this)
    }
  },
}

</script>

<style>

div.anypost-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
  padding-left: 8px;
  padding-right: 8px;
}

div.row{
  margin-top: 10px;
  margin-bottom: 10px;
}

h1.anypost-title{
  font-size: 3rem;
}

span.anypost-title-domain{
  font-size: 1.5rem;
}

div.anypost-subtitle {
  font-size: 0.9rem;
}

div.anypost-space{
  margin-top: 45px;
  margin-bottom: 45px;
}

img.anypost-server-brandlogo{
  margin-right: 15px;
  margin-top: 13px;
  margin-bottom: 13px;
}

button.anypost-post-button {
  font-size: 1.3rem;
  display: block;
  margin-top: 8px;
  margin-bottom: 18px;
  height: 60px;
}

input.anypost-input-additional-instance {
  height: 40px;
}

button.anypost-button-add-instance {
  padding: 0;
  font-size: 0.9rem;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.anypost-link {
  font-size: 0.9rem;
  color: #9D9087;
  text-decoration: none;
}

.anypost-important-link {
  padding: 7px;
}

a.anypost-header-link, a.anypost-header-link:hover{
  color: #2c3e50;
  text-decoration: none;
}

.align-items-center {
  display: grid;
}

.anypost-post-button-text {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}


</style>
