var instance_type_cache = [
  {instance_name: "twitter.com", instance_type: "twitter"},
  {instance_name: "facebook.com", instance_type: "facebook"},
  {instance_name: "mstdn.jp", instance_type: "mastodon"},
  {instance_name: "mastodon.cloud", instance_type: "mastodon"},
  {instance_name: "mastodon.social", instance_type: "mastodon"},
  {instance_name: "pawoo.net", instance_type: "mastodon"},
  {instance_name: "best-friends.chat", instance_type: "mastodon"},
  {instance_name: "fedibird.com", instance_type: "fedibird"},
  {instance_name: "misskey.io", instance_type: "misskey"},
  {instance_name: "blob.cat", instance_type: "pleroma"},
]

var INSTANCE_TYPE_MISSKEY = 'misskey'
const Misskey = {
  name: INSTANCE_TYPE_MISSKEY,
  icon: "assets/brand/misskey.png",
  availableParams: ["text", "visibility", "url"],
  availableVisibility: ["public", "home", "followers", "specified"],
  setQueryParam: function(app){
    let redirect_url = new URL("https://" + app.instance_name + "/share")
    if (app.text       != "") {redirect_url.searchParams.set('text', app.text)}
    if (app.url        != "") {redirect_url.searchParams.set('url', app.url)}
    if (app.visibility != "") {redirect_url.searchParams.set('visibility', app.visibility)}
    return redirect_url
  }
}

var INSTANCE_TYPE_MASTODON = 'mastodon'
const Mastodon = {
  name: INSTANCE_TYPE_MASTODON,
  icon: "assets/brand/mastodon.png",
  availableParams: ["text", "visibility", "url"],
  availableVisibility: ["public", "unlisted", "private", "direct"],
  setQueryParam: function(app){
    let redirect_url = new URL("https://" + app.instance_name + "/share")
    if (app.text       != "") {redirect_url.searchParams.set('text', app.text)}
    if (app.url        != "") {redirect_url.searchParams.set('url', app.url)}
    if (app.visibility != "") {redirect_url.searchParams.set('visibility', app.visibility)}
    return redirect_url
  }
}

var INSTANCE_TYPE_PLEROMA = 'pleroma'
const Pleroma = {
  name: INSTANCE_TYPE_PLEROMA,
  icon: "assets/brand/pleroma.png",
  availableParams: ["text", "url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://" + app.instance_name + "/")
    if (app.text       != "") {redirect_url.searchParams.set('message', app.text)}
    if (app.url        != "") {redirect_url.searchParams.set('url', app.url)}
    return redirect_url
  }
}

var INSTANCE_TYPE_GNUSOCIAL = 'gnusocial'
const Gnusocial = {
  name: INSTANCE_TYPE_GNUSOCIAL,
  icon: "assets/brand/gnusocial.png",
  availableParams: ["text", "url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://" + app.instance_name + "/notice/new")
    if (app.text != "" || app.url != "") redirect_url.searchParams.set('status_textarea', app.text + " " + app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_DIASPORA = 'diaspora'
const Diaspora = {
  name: INSTANCE_TYPE_DIASPORA,
  icon: "assets/brand/diaspora.png",
  availableParams: ["text", "url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://" + app.instance_name + "/bookmarklet")
    if (app.text       != "") {redirect_url.searchParams.set('notes', app.text)}
    if (app.url        != "") {redirect_url.searchParams.set('url', app.url)}
    return redirect_url
  }
}

var INSTANCE_TYPE_FRIENDICA = 'friendica'
const Friendica = {
  name: INSTANCE_TYPE_FRIENDICA,
  icon: "assets/brand/friendica.png",
  availableParams: ["text", "url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://" + app.instance_name + "/bookmarklet")
    if (app.text       != "") {redirect_url.searchParams.set('title', app.text)}
    if (app.url        != "") {redirect_url.searchParams.set('url', app.url)}
    return redirect_url
  }
}

var INSTANCE_TYPE_TWITTER = 'twitter'
const Twitter = {
  name: INSTANCE_TYPE_TWITTER,
  icon: "assets/brand/twitter.png",
  availableParams: ["text", "url", "hashtag"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://twitter.com/intent/tweet")
    if (app.text    != "") redirect_url.searchParams.set('text', app.text)
    if (app.url     != "") redirect_url.searchParams.set('url', app.url)
    if (app.hashtag != "") redirect_url.searchParams.set('hashtags', app.hashtag)
    return redirect_url
  }
}

var INSTANCE_TYPE_FACEBOOK = 'facebook'
const Facebook = {
  name: INSTANCE_TYPE_FACEBOOK,
  icon: "assets/brand/facebook.png",
  availableParams: ["url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://facebook.com/sharer/sharer.php")
    if (app.url     != "") redirect_url.searchParams.set('u', app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_LINKEDIN = 'linkedin'
const Linkedin = {
  name: INSTANCE_TYPE_LINKEDIN,
  icon: "assets/brand/linkedin.png",
  availableParams: ["url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://www.linkedin.com/sharing/share-offsite/")
    if (app.url     != "") redirect_url.searchParams.set('u', app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_LINE = 'line'
const Line = {
  name: INSTANCE_TYPE_LINE,
  icon: "assets/brand/line.png",
  availableParams: ["url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://social-plugins.line.me/lineit/share")
    if (app.url     != "") redirect_url.searchParams.set('url', app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_HATENA = 'hatenabookmark'
const Hatena = {
  name: INSTANCE_TYPE_HATENA,
  icon: "assets/brand/hatenabookmark.png",
  availableParams: ["text", "url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://b.hatena.ne.jp/entry/panel/")
    if (app.text    != "") redirect_url.searchParams.set('btitle', app.text)
    if (app.url     != "") redirect_url.searchParams.set('url', app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_POCKET = 'pocket'
const Pocket = {
  name: INSTANCE_TYPE_POCKET,
  icon: "assets/brand/pocket.png",
  availableParams: ["url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://www.xing.com/spi/shares/new")
    if (app.url     != "") redirect_url.searchParams.set('url', app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_PINTEREST = 'pinterest'
const Pinterest = {
  name: INSTANCE_TYPE_PINTEREST,
  icon: "assets/brand/pinterest.png",
  availableParams: ["url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://www.pinterest.com/pin/create/button/")
    if (app.url     != "") redirect_url.searchParams.set('url', app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_XING = 'xing'
const Xing = {
  name: INSTANCE_TYPE_XING,
  icon: "assets/brand/other.png",
  availableParams: ["url"],
  availableVisibility: [],
  setQueryParam: function(app){
    let redirect_url = new URL("https://www.xing.com/spi/shares/new")
    if (app.url     != "") redirect_url.searchParams.set('url', app.url)
    return redirect_url
  }
}

var INSTANCE_TYPE_ERROR = 'unknown'
const Not_supported_instance_type = {
  name: INSTANCE_TYPE_ERROR,
  icon: "assets/brand/other.png",
  availableParams: [],
  availableVisibility: [],
  setQueryParam: function(){
    return "/"
  }
}

var getInstanceConst = function(instance_type){
  switch (instance_type){
  case INSTANCE_TYPE_MISSKEY:
    return Misskey
  case "azpick":
    return Misskey
  case INSTANCE_TYPE_MASTODON:
    return Mastodon
  case "fedibird":
    return Mastodon
  case INSTANCE_TYPE_PLEROMA:
    return Pleroma
  case "akkoma":
    return Pleroma
  case INSTANCE_TYPE_GNUSOCIAL:
    return Gnusocial
  case INSTANCE_TYPE_DIASPORA:
    return Diaspora
  case INSTANCE_TYPE_FRIENDICA:
    return Friendica
  case INSTANCE_TYPE_TWITTER:
    return Twitter
  case INSTANCE_TYPE_FACEBOOK:
    return Facebook
  case INSTANCE_TYPE_LINKEDIN:
    return Linkedin
  case INSTANCE_TYPE_LINE:
    return Line
  case INSTANCE_TYPE_HATENA:
    return Hatena
  case INSTANCE_TYPE_POCKET:
    return Pocket
  case INSTANCE_TYPE_PINTEREST:
    return Pinterest
  case INSTANCE_TYPE_XING:
    return Xing
  default:
    return Not_supported_instance_type
  }
}

var getInstanceInfoFromBrowserCache = function(instance_name) {
  var res = Not_supported_instance_type
  instance_type_cache.forEach(function(hash) {
    if (hash['instance_name'] == instance_name) {
      res = getInstanceConst(hash['instance_type'])
    }
  })
  return res
}

var getInstanceInfoFromAPI = function(instance_name) {
  var request = new XMLHttpRequest();
  request.open('GET', "https://anypost.dev/api/v1/instances/" + instance_name, false)
  request.send()
  if (request.status === 200) {
     var api = JSON.parse(request.responseText)
     if (api['type'] == "unknown") {
       return Not_supported_instance_type
     }
     return getInstanceConst(api['type'])
  }
  return Not_supported_instance_type
}

export var getInstanceInfo = function(instance_name){

  if (instance_name == "" || instance_name === undefined) {
    return Not_supported_instance_type
  }

  var instance_info = getInstanceInfoFromBrowserCache(instance_name)

  if (instance_info.name == INSTANCE_TYPE_ERROR) {
    instance_info = getInstanceInfoFromAPI(instance_name)
    if (instance_info.name != INSTANCE_TYPE_ERROR) {
      instance_type_cache.push({instance_name: instance_name, instance_type: instance_info.name})
    }
  }

  return instance_info
}
