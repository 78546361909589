export var messages = {
  en: {
    message: {
      subtitle: "Post to share anything, to anywhere, with anyone.",
      post: "Post!",
      server_type: "Type",
      server: "Server name",
      server_not_listed: "(Enter server name if not listed)",
      server_add: "Add",
      post_text: "Text/Title",
      post_url: "URL",
      post_hashtag: "Hashtag",
      post_visibility: "Visibility",
      gdpr: {
        header: "Cookie settings",
        header_message: "We use Cookies and Google Analytics to help personalize content and offer a better experience. If disabled, your settings of destination server will be lost. All data will be anonymized.",
        header_accept: "Accept All",
        header_preference: "Preference",
        message: "Your privacy settings",
        message_pii_notice: "Each settings does NOT lead to send your identifiable information to the administrator.",
        title_basic: "Save your preference",
        description_basic: "To save your settings about server or language for your next visit. Activation is strongly recommended.",
        title_additional: "Cooperation for diagnostic data collection",
        description_additional: "To report statistics on performance and site problems, and use to improve user experience. This is critial to maintain this site, so please kindly help us by activating it.",
        cookie: "Basic Cookie",
        ga: "Google Analytics",
        save: "Save",
        decline: "Decline",
      },
      clear_settings: "Reset privacy aggrement",
      redirect: "You will be automatically redirected...",
      redirect_stop: "Stop redirect",
      not_supported_type: "Sorry, but your destination is not supported.",
      modal_close: "Dismiss",
      about: "About AnyPost.dev",
      about_detail: "AnyPost.dev allows you to share on various SNS around the world from a single link. mastodon, misskey, pleroma, diaspora, twitter, facebook, ... are supported.",
      about_learn_more: "Learn more",
      landing_about: "This service allows you to share on various SNS around the world from a single link. ",
      landing_try_to_use: "Visit tool UI",
      landing_how_to_use: "Learn more about how to use our share button",
      landing_support_platform: "Supported platform",
      landing_share_button: "Set sharing button on your site",
      landing_bookmarklet: "Use bookmarklet on your browser",
      landing_more_service: "and more...",
      landing_admin: "Contect",
      landing_admin_sentence: "This service is operated by Han(highemerly).",
      landing_contacts: "Contact admin",
      tbc: "and more...",
    },
    link: {
      top: "TOP",
      privacy_policy: "Privacy policy & Rights",
      how_to_use: "How to use",
      share: "Share page"
    },
    spec: {
      sample: "Sample",
      code: "Code",
      example: "e.g.",
      title: "Usage",
      title_browser: "Use on your browser",
      title_site: "Setup share button on your site",
      title_supported: "Supported platform",
      lead_sentence_title: "This page describes usage of anypost.dev.",
      title_bookmarklet: "Use bookmarklet",
      lead_sentence_bookmarklet: "Let's bookmark above javascript code to share website anytime, via anypost.dev.",
      title_simple_button: "Use simple sharing button",
      lead_sentence_simple_button: "Simplely copy and paste above HTML code, to introduce anypost.dev button on your site.",
      title_customize_button: "Customize sharing button",
      lead_sentence_custom_button: "Enter above input field to customize button's action and appearance. After that, please copy and paste displayed HTML code.",
      title_url_spec: "Develop sharing button (For developer)",
      lead_sentence_url_spec: "Request https://anypost.dev/share with QSP (Query Strings Parameters) described below.",
      query_parameter: "QSP",
      value: "Explaination",
      value_text: "Text",
      value_url: "URL",
      value_hashtag: "Hashtag",
      value_visibility: "Visibility",
      value_redirect: "Redirect automatically",
      value_instance: "Default destination",
      notation_please_check: "(Check the following note)",
      notation_option: "All QSP are NOT mandatory.",
      notation_supported_parameter: "Not all paramter are supported by all destination. If some paremeters are not supported by your destination, it will be discarded. (For example, hashtags will be discarded by mastodon server.)",
      notation_supported_visibility_instance: "'Visibility' is supported by only mastodon and misskey. The acceptable values are public/unlisted/private/direct at mastodon, and public/home/followers/specified at misskey.",
      notation_instance: "'Default destination' should be specified FQDN of destination, like 'misskey.io'. Please keep in mind that end user can change destination even if default destination is specified.",
      notation_redirect: "'Redirect automatically' feature allows the user to automatically transition to the sharing screen of destination without any user action. The value should be '1' or 'true'. If you use this parameter, 'Default destination'(i) will be mandatory parameter.",
      customize_appearance_description: "Decide strings and icon on sharing button.",
      customize_appearance_detail_description: "Choose appearance settings.",
      customize_appearance: {
        color: "Background color",
        font_size: "Font size",
        image_size: "Icon size",
        width: "Button width",
        height: "Button height",
        padding: "Padding",
        button_strings: "Button strings",
        button_strings_small: "Button strings (small)",
        font_color: "Font color",
      },
      customize_icon: "Icon",
      customize_action_description: "Choose action when button is clicked",
      customize_action: {
        url: "URL only",
        url_title: "URL, page title",
        url_title_strings: "URL, page title, additional fixed strings",
        strings: "Fixed strings only",
        default_instance_specified: "Yes",
        default_instance_not_specified: "No",
      },
      customize_post_contents: "Contents to post",
      customize_default_instance_is_specified: "Speficy default destination",
      customize_default_instance: "Default destination (server name)",
      customize_fixed_strings: "Fixed strings",
      customize_icon_none: "none",
    },
    term: {
      japanease: "Note that in the event of any discrepancy between this English version statement and the original Japanease version statement, the Japanease version statement shall prevail over this English version.",
      title_terms: "Notice",
      terms: {
        lead: "When using anypost.dev (referred to \"the service\"), please be aware of the following",
        no_warranty: "In case of any damage caused by using of or inability using this service, the operator of the service will not compensate you in any way, in anytime.",
        no_support: "The operator of the service will use its best efforts to maintain and improve the service to the extent practicable. However, thare are no guarantee to provide the service.",
        link_to_3rd_party: "Social networking services and other services linked by the service (referred to \"SNS and other services\") are not related to the service or the operator of the service. We do not guarantee that SNS and other services. Please use them on your risk. Please follow terms and conditions of SNS and other services.",
        ban: "For the legitimate operation of the service, the operator of the service may restrict access from specific users or postings to specific servers when the operator thinks it unavoidable.",
      },
      title_privacy: "Privacy Policy",
      privacy: {
        lead: "anypost.dev (referred to \"the service\") is operated with the following privacy policy.",
        title_pii: "Personal Information",
        pii: "\"Personal information\" refers to Personal information as defined in the Personal Information Protection Law in Japan, and refers to information about a living individual that can identify a specific individual and information that can identify a specific individual from said information alone (personal identification information).",
        title_gathering: "Personal Information We May Collect",
        gathering: {
          ipaddress: "IP address",
          metadata: "Metadata sent by browsers and other software and hardware",
        },
        title_usage: "Usage of Personal Information",
        usage: {
          lead: "The information will be used only for the following purposes. Any changes to the usage will be announced in advance in accordance with a separate privacy policy change.",
          ban: "Access restrictions (limited to those essential to the legitimate operation of the service, such as when under attack)",
          inquery: "User inquiries",
          other: "Other purposes incidental to the above",
        },
        title_party: "Provide Personal Information to Others",
        party: {
          lead: "No, except in the following cases:",
          life: "For the protection of a person's life or property and it is extremely difficult to obtain the consent of the person",
          children: "For improving public health or promoting the sound growth of children and it is extremely difficult to obtain the consent of the individual concerned",
          government: "To cooperate with a national agency, a local government, or an individual or entity entrusted by either a national agency or local government to execute affairs prescribed by law, and obtaining the consent of the individual may significantly impede the execution of such affairs.",
        },
        title_disclose: "Disclosure Personal Information",
        disclose: {
          lead: "When a person requests disclosure of his/her personal information, we will make every practicable effort to disclose such information to the person without delay. However, if disclosure would result in any of the following cases, we may not disclose some or all of the information. A fee of 1,000 japanease yen per disclosure will be charged.",
          life: "If there is a risk of harm to the life, body, property, or other rights or interests of the person concerned or a third party",
          ban: "If there is a risk of significant hindrance to the legitimate operation of the service",
          low: "If there is a risk that disclosure may violate laws and regulations",
          end: "Requests for disclosure as set forth in this section shall be made through the \"Contact\" section provided separately.",
        },
        title_delete: "Correction, Deletion, Using Suspension of Personal Information",
        delete: {
          first: "If a person requests that his/her personal information be corrected on the grounds that it is incorrect, we will, without delay and through practically feasible efforts, conduct the necessary investigation. In addition, if the individual requests the suspension of use or deletion of his/her personal information on the grounds that it has been handled beyond the scope of the Privacy Policy or that it has been obtained through wrongful means, the Company will make every practicable effort to conduct the necessary investigation without delay.",
          second: "In either case, if the operator of the service deems it necessary to respond to the request, it will correct, delete, or suspend use of the information. In any case, if the operator of the service is unable to comply with the request, the service will notify the customer of such failure.",
          third: "Requests for correction, deletion, or discontinuance of use as specified in this section shall be made through the \"Contact \" section provided separately.",
        },
        title_cookie: "Cookie",
        cookie: "The service uses cookies only when the user has given permission. Cookies are data that a Web site can store on a computer, which helps the Web site identify the browser. The service uses cookies only to store user preferences in the browser for future use, and not for the purpose of identifying individuals. Specifically, cookie is used for the following purposes:",
        cookie_list: {
          language_settings: "Save language settings of UI.",
          server_settings: "Save sharing page settings, like your destination server name.",
          ga: "Use Google Analytics feature. (Only in case you approved the usage of Google Analytics.)",
        },
        title_webservice: "Web Service",
        webservice: {
          lead: "The service uses the following services to improve the service.",
          ga: "Google: Google Analytics",
        },
        title_change: "Change Privacy Policy",
        change: "The Privacy Policy may be changed without notice to users. The new Privacy Policy will become effective immediately after it is posted on this Web site.",
        title_inquery: "Inquery",
        inquery: "Please refer to the \"Contact\" section listed at the bottom of this page.",
      },
      title_copyrights: "Copyrights Statement",
      copyrights: {
        header: "",
        logo: "The copyrights of logo marks which describes other services belong to their respective owners.",
        logo_misskey: "Misskey's logo mark is licensed by CC BY-NC-SA 4.0.",
        logo_gnusocial: "gnusocial's logo mark is licenced by CC BY-SA 4.0.",
      },
      title_contacts: "Contact",
      contacts: {
        header: "anypost.dev ('the service') is privately operated by Han (highemerly). For inquiries, please visit link below.",
        law_rights: "Legal claim (including 'Privacy Policy' and 'Copyrights')",
        other: "Other general inquery",
      }
    }
  },
  ja: {
    message: {
      subtitle: "何でも、誰にでも、どこにでも。投稿してシェアしよう。",
      post: "投稿する！",
      server_type: "種別",
      server: "投稿サーバ",
      server_not_listed: "(投稿サーバがない場合は入力)",
      server_add: "追加",
      post_text: "投稿内容",
      post_url: "投稿URL",
      post_hashtag: "投稿ハッシュタグ",
      post_visibility: "投稿範囲",
      gdpr: {
        header: "Cookieの設定",
        header_message: "あなたのエクスペリエンス向上のため、CookieやGoogle Analyticsを使用します。無効にすると、毎回投稿先などの設定が必要になります。全てのデータは匿名で扱われます。",
        header_accept: "全て許可",
        header_preference: "詳細設定",
        message: "あなたのプライバシー設定",
        message_pii_notice: "いずれの設定を有効にしても、あなたを特定可能な情報が管理者に送信されることは決してありません。",
        title_basic: "設定の保存",
        description_basic: "あなたの投稿設定や言語設定を保存し、次回のアクセス時に入力する手間を省くために使います。有効化を強く推奨します。",
        title_additional: "運営への協力",
        description_additional: "パフォーマンスやサイトの問題に関する統計情報を報告し、ユーザ体験改善だけに利用します。サイトの維持・運営に極めて重要ですので、有効化にご協力ください。",
        cookie: "Cookieの利用",
        ga: "Google Analyticsの利用",
        save: "設定を保存",
        decline: "拒否",
      },
      clear_settings: "プライバシー権限のリセット",
      redirect: "自動的にリダイレクトされます...",
      redirect_stop: "中断",
      not_supported_type: "その投稿先はサポートされていません。",
      modal_close: "了解",
      about: "AnyPost.devについて",
      about_detail: "AnyPost.devは、ブログなどに設置した1つのリンクから、世界中の様々なSNSにシェアできるサービスです。mastodon, misskey, pleroma, diaspora, twitter, facebook, ...などに対応しています。",
      about_learn_more: "もっと見る",
      landing_about: "ブログやサイトに1つだけリンクを設置することで、世界中の様々なSNSにシェアできるサービスです。",
      landing_try_to_use: "投稿画面を見る",
      landing_how_to_use: "ボタンの設置方法をもっと見る",
      landing_support_platform: "サポートしているプラットフォーム",
      landing_share_button: "ページシェアボタンを設置する",
      landing_bookmarklet: "ブラウザでブックマークレットを利用する",
      landing_more_service: "その他",
      landing_admin: "運営者について",
      landing_admin_sentence: "はん(highemerly)が運営しています。",
      landing_contacts: "運営者に連絡する",
      tbc: "...など",
    },
    link: {
      top: "トップ",
      privacy_policy: "プライバシーポリシー・権利",
      how_to_use: "使い方",
      share: "シェアページ",
    },
    spec: {
      sample: "サンプル",
      code: "コード",
      example: "例",
      title: "使い方",
      title_browser: "ブラウザで利用する",
      title_site: "あなたのサイトにボタンを設置する",
      title_supported: "対応している投稿先",
      lead_sentence_title: "ブックマークレットの利用方法・サイトへのボタン設置方法をご案内します。",
      title_bookmarklet: "ブックマークレットを登録する",
      lead_sentence_bookmarklet: "ブラウザのお気に入り機能を使って以下のコードを登録しておくと、今見ているページをシェアするためにいつでもanypost.devを利用できます。",
      title_simple_button: "ページをシェアするボタンを設置する",
      lead_sentence_simple_button: "以下のコードをサイトに貼り付けるだけで、あなたのサイトを簡単にanypost.devに対応させることができます。",
      title_customize_button: "カスタマイズしたボタンを設置する",
      lead_sentence_custom_button: "以下のフォームを入力し、ボタンの外観や動作をカスタマイズできます。カスタマイズが終わったら、表示されるコードをサイトに貼り付けてください。",
      title_url_spec: "ボタンを自由に開発して設置する（デベロッパー向け）",
      lead_sentence_url_spec: "https://anypost.dev/share に以下のクエリ文字列を追加すると、投稿内容や投稿先を自由にカスタマイズできます。",
      query_parameter: "クエリ文字列",
      value: "内容",
      value_text: "本文",
      value_url: "URL",
      value_hashtag: "ハッシュタグ",
      value_visibility: "ビジビリティ",
      value_redirect: "自動リダイレクト",
      value_instance: "デフォルトの投稿先",
      notation_please_check: "（値は表下の注釈参照）",
      notation_option: "全てのパラメータは必須ではなく、オプションです。",
      notation_supported_parameter: "全てのパラメータがすべての投稿先に対応しているわけではありません。対応していない投稿先の場合、そのパラメータは無視されます（例：Mastodonではハッシュタグは無視されます）。",
      notation_supported_visibility_instance: "ビジビリティはmastodonとmisskeyのみ対応しており、mastodonは public/unlisted/private/direct から、misskeyは public/home/followers/specified から選択できます。",
      notation_instance: "デフォルトの投稿先は、例えば misskey.io のように指定します。指定してもユーザが投稿前に変更できます。",
      notation_redirect: "自動リダイレクト機能を有効にすると、ユーザの操作がなくても自動で各サービスの投稿画面にリダイレクトされます。a=1またはa=trueと指定してください。ただし、デフォルトの投稿先（i）の指定が必須です。",
      customize_appearance_description: "ボタンの文字列・アイコンを決めます。",
      customize_appearance_detail_description: "ボタンの詳細な外観をカスタマイズしたい場合は、変更します。",
      customize_appearance: {
        color: "背景色",
        font_size: "文字サイズ",
        image_size: "アイコンサイズ",
        width: "全体の横幅",
        height: "全体の高さ",
        padding: "ボタン内の余白",
        button_strings: "表示する文字",
        button_strings_small: "表示する文字(下段)",
        font_color: "文字色",
      },
      customize_icon: "アイコン",
      customize_action_description: "ボタンを押したときの動作を決定します。",
      customize_action: {
        url: "URLのみ",
        url_title: "URL・タイトル",
        url_title_strings: "URL・タイトルと末尾に固定文字列を追加",
        strings: "固定文字列のみ",
        default_instance_specified: "指定する",
        default_instance_not_specified: "指定しない",
      },
      customize_post_contents: "投稿内容",
      customize_default_instance_is_specified: "投稿先を指定",
      customize_default_instance: "投稿先（サーバ名）",
      customize_fixed_strings: "固定文字列",
      customize_icon_none: "なし",
    },
    term: {
      japanease: "",
      title_terms: "注意事項",
      terms: {
        lead: "anypost.dev（以下、「本サービス」）のご利用時は、以下をご了承ください。",
        no_warranty: "本サービスを利用したこと、および利用できなかったことによる損害は、いかなる場合も補償しません。",
        no_support: "本サービスの運営者は、本サービスの維持や向上のため、現実的に可能な範囲での努力を行います。サービスの提供に関して、なんら保障を行うものではありません。",
        link_to_3rd_party: "本サービスからリンクされたサーバにて提供されてるソーシャルネットワーキングサービスをはじめとするサービス（以下、「本サービスから投稿できるSNS等」）は、本サービスおよび本サービスの運営者とは一切関係ありません。本サービスから投稿できるSNS等が正当に運営されていることを保証するものではありませんので、利用是非は個々人で行ってください。また、投稿の内容や利用方法については、本サービスから投稿できるSNS等の利用規約に従ってください。",
        ban: "本サービスの正当な運営のため、本サービスの運営者がやむを得ないと判断した場合、特定ユーザからのアクセスや特定サーバへの投稿などを制限する場合があります。",
      },
      title_privacy: "プライバシーポリシー",
      privacy: {
        lead: "anypost.dev（以下、「本サービス」）は、以下のプライバシーポリシーに基づいて運営されます。",
        title_pii: "個人情報",
        pii: "「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、特定の個人を識別できる情報及び当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。",
        title_gathering: "収集する個人情報",
        gathering: {
          ipaddress: "IPアドレス",
          metadata: "ブラウザ等から送信されるメタデータ",
        },
        title_usage: "個人情報の利用方法",
        usage: {
          lead: "以下の目的でのみ利用します。利用方法を変更する場合、別に定められるプライバシーポリシーの変更に従って、事前に公表します。",
          ban: "アクセス制限（攻撃を受けている場合など、サービスの正当な運営に必要不可欠なものに限る）",
          inquery: "ユーザからの問い合わせ対応",
          other: "その他、上記に付随する目的",
        },
        title_party: "個人情報の第三者への提供",
        party: {
          life: "人の生命または財産の保護のために必要で、かつ本人の同意を得ることが著しく困難である場合",
          children: "公衆衛生の向上または児童の健全な育成の推進のために必要で、かつ本人の同意を得ることが著しく困難である場合",
          government: "国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要があり、かつ本人の同意を得ることにより当該事務の遂行に多大な支障を及ぼす恐れがある場合",
        },
        title_disclose: "個人情報の開示",
        disclose: {
          lead: "本人からその個人情報の開示を求められたとき、本人に対し、遅延無く現実的に可能な範囲での努力を行い、これを開示します。ただし、開示することにより以下のいずれかに該当する場合、その一部または全部を開示しないこともあります。なお、開示に際しては、1件1,000円の手数料を申し受けます。",
          life: "本人または第三者の生命・身体・財産その他の権利利益を害するおそれがある場合",
          ban: "本サービスの正当な運営に著しい支障を及ぼすおそれがある場合",
          low: "開示により法令に違反するおそれがある場合",
          end: "なお、本項で定める開示の請求は、別途定める「お問い合わせ先」から請求することとします。",
        },
        title_delete: "個人情報の訂正・削除・利用停止",
        delete: {
          first: "本人から、個人情報が誤った情報であるという理由により、その訂正を求められた場合、遅滞なく現実的に可能な範囲での努力を通じて必要な調査を行います。また、本人から、個人情報がプライバシーポリシーで定められた範囲を超えて取り扱われているという理由・不正の手段により取得されたものであるという理由により、その利用の停止または消去を求められた場合、遅滞なく現実的に可能な範囲での努力を通じて必要な調査を行います。",
          second: "いずれの場合も、本サービスの運営者がその請求に応じる必要があると判断した場合は訂正・削除・利用停止を行います。また、いずれの場合も、その請求に応じられない場合は、その通知を行います。",
          third: "なお、本項で定める訂正・削除・利用停止の請求は、別途定める「お問い合わせ先」から請求することとします。",
        },
        title_cookie: "クッキーの利用",
        cookie: "本サービスでは、ユーザが許可した場合にのみクッキーを利用します。クッキーはWebサイトがコンピュータに保存することのできるデータで、Webサイトがブラウザを識別することに役立ちます。本サービスでは、将来の利用のため、ユーザの設定情報をブラウザ保存するためのみに用い、個人を識別する目的では利用しません。具体的には、以下の用途に利用しています。",
        cookie_list: {
          language_settings: "表示されるUIの言語設定を保存するため。",
          server_settings: "過去投稿先のサーバとして利用したサーバ名など、シェア画面の各種設定を保存するため。",
          ga: "Google Analytics利用のため（Google Analyticsを有効にした場合のみ）。",
        },
        title_webservice: "Webサービスの利用",
        webservice: {
          lead: "本サービスでは、サービス改善のため、以下の外部のWebサービスを利用しています。",
          ga: "Google社: Google Analytics",
        },
        title_change: "プライバシーポリシーの変更",
        change: "プライバシーポリシーは、ユーザに通知することなく変更できるものとします。新しいプライバシーポリシーは、本ウェブサイトに掲載直後から効力を生じるものとします。",
        title_inquery: "お問い合わせ先",
        inquery: "このページ下部に記載の「お問い合わせ先」を参照してください。",
      },
      title_copyrights: "権利に関する掲示",
      copyrights: {
        header: "",
        logo: "anypost.dev（以下、「本サービス」）で利用している他サービスのロゴの権利は、各権利者に帰属します。",
        logo_misskey: "misskeyのロゴはCC BY-NC-SA 4.0でライセンスされています。",
        logo_gnusocial: "gnusocialのロゴはCC BY-SA 4.0でライセンスされています。",
      },
      title_contacts: "お問い合わせ先",
      contacts: {
        header: "anypost.dev（以下、「本サービス」）は、はん（highemerly）が個人で運営しています。本サービスに関するお問い合わせは以下リンクからお願いします。",
        law_rights: "権利を行使する要求・権利に関する問い合わせ（プライバシーポリシー、著作権等）",
        other: "その他の一般的な問い合わせ",
      }
    },
  },
};
