 <template>
  <div class="container anypost-container" id="top">

    <div class="row anypost-row-header">
      <h1 class="anypost-title"><router-link to="/" class="anypost-header-link">AnyPost<span class="anypost-title-domain">.dev</span></router-link></h1>
      <div class="anypost-subtitle">{{ t("message.subtitle")}}</div>
    </div>

    <div class="row">
      <div class="col-12 text-start align-items-center">
        <div class="anypost-row-top-description">
          {{ t("message.landing_about") }}
          <br>
          <router-link to="/share" class="anypost-important-link"> >> {{ t("message.landing_try_to_use") }}</router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-start align-items-center">
        <h4>{{ t("message.landing_support_platform") }}</h4>
        Fediverse:
        <div class="row">
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/mastodon.png" class="anypost-server-brandlogo" alt="mastodon">
              <figcaption class="anypost-server-brandname">mastodon</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/pleroma.png" class="anypost-server-brandlogo" alt="pleroma">
              <figcaption class="anypost-server-brandname">pleroma</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/misskey.png" class="anypost-server-brandlogo" alt="pleroma">
              <figcaption class="anypost-server-brandname">misskey</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/diaspora.png" class="anypost-server-brandlogo" alt="pleroma">
              <figcaption class="anypost-server-brandname">diaspora</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/friendica.png" class="anypost-server-brandlogo" alt="pleroma">
              <figcaption class="anypost-server-brandname">friendica</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/gnusocial.png" class="anypost-server-brandlogo" alt="pleroma">
              <figcaption class="anypost-server-brandname">gnusocial</figcaption>
            </figure>
          </div>
        </div>
        Non-Fediverse:
        <div class="row">
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/twitter.png" class="anypost-server-brandlogo" alt="mastodon">
              <figcaption class="anypost-server-brandname">twitter</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/facebook.png" class="anypost-server-brandlogo" alt="mastodon">
              <figcaption class="anypost-server-brandname">facebook</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/linkedin.png" class="anypost-server-brandlogo" alt="mastodon">
              <figcaption class="anypost-server-brandname">linkedin</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/pocket.png" class="anypost-server-brandlogo" alt="mastodon">
              <figcaption class="anypost-server-brandname">pocket</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/pinterest.png" class="anypost-server-brandlogo" alt="mastodon">
              <figcaption class="anypost-server-brandname">pinterest</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <figure class="anypost-server">
              <img src="@/assets/brand/line.png" class="anypost-server-brandlogo" alt="mastodon">
              <figcaption class="anypost-server-brandname">line</figcaption>
            </figure>
          </div>
          <div class="col-auto">
            <div class="anypost-server-brandname">{{ t("message.tbc")}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-start align-items-center">
        <h4>{{ t("message.landing_share_button")}}</h4>
        {{ t("spec.sample")}}:
        <a href="https://anypost.dev/share"  onclick="window.open(this.href+'?s='+location.href+'&t='+document.title, '', 'width=500,height=750'); return false;" style="color: white; text-decoration-line: none; width: 91px;">
          <div style="display: inline-block; background: #000080; padding: 4px; margin: 4px 6px; border-radius: 5px; text-align: center; width: 80px; line-height: 7px">
            <img src="https://anypost.dev/external-assets/share.svg" width="14" alt="share">
            Share
            <div style="font-size: 0.5em;">via anypost.dev</div>
          </div>
        </a>

        {{ t("spec.code")}}:
       <textarea readonly class="form-control anypost-code"><a href="https://anypost.dev/share" onclick="window.open(this.href+'?s='+location.href+'&amp;t='+document.title, '', 'width=500,height=750'); return false;" style="color: white; text-decoration-line: none; width: 91px;"><div style="display: inline-block; background: rgb(0, 0, 128); padding: 4px; margin: 4px 6px; border-radius: 5px; text-align: center; width: 80px; line-height: 7px;"><img src="https://anypost.dev/external-assets/share.svg" width="14" alt="share"> Share <div style="font-size: 0.5em;">via anypost.dev</div></div></a></textarea>
        <router-link to="/howto" class="anypost-important-link"> >> {{ t("message.landing_how_to_use") }}</router-link>
      </div>
      <div class="col-12 text-start align-items-center">
        <h4>{{ t("message.landing_bookmarklet")}}</h4>
        {{ t("spec.code")}}:
        <textarea readonly class="form-control anypost-code">javascript:window.open('https://anypost.dev/share?s=%27+location.href+%27&t=%27+document.title, %27%27, %27width=500,height=750%27)</textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-start align-items-center">
        <h4>{{ t("message.landing_admin") }}</h4>
        {{ t("message.landing_admin_sentence") }}
        <br>
        <a href="https://highemerly.net/#contact" target="_blank" class="anypost-important-link"> >> {{ t("message.landing_contacts") }}</a>
      </div>
    </div>

    <FooterText
    :appear_footer_sentence=false
    :enable_cookie=true />

  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import FooterText from './FooterText.vue';
import { messages } from '@/locales/local.js';

export default {

  components: {
    FooterText,
  },

  setup() {
    const { t } = useI18n({
      legacy: false,
      locale: navigator.language.split("-")[0],
      fallbackLocale: "en",
      messages: messages,
    })
    const msg = computed(() => t('msg'))
    return { t, msg }
  },

  data() {
    return {
      locale: navigator.language.split("-")[0],
    }
  },

}

</script>

<style>

h4{
  padding-top: 0.5em;
}

span.anypost-title-domain{
  font-size: 1rem;
}

div.anypost-subtitle {
  font-size: 0.8rem;
}

div.anypost-row-top-description {
  margin-top: 15px;
  margin-bottom: 15px;
}

.anypost-server-brandname {
  font-size: 0.9rem;
  margin-top: 0rem;
  margin-bottom: 0.9rem;
}

figure.anypost-server > .anypost-server-brandlogo {
  height: 33px;
  margin: 9px;
}

.anypost-server-brandname-textonly {
  margin: 3px 10px;
}

.anypost-server {
  margin: 4px;
}

</style>
