<template>
<div v-if="loading" class="anypost-button-add-instance-loadings"></div>
<div v-if="!loading">{{default_text}}</div>
</template>

<script>

export default {
  name: 'LoadingText',
  props: {
    default_text: String,
    loading: Boolean,
  },
}

</script>

<style>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.anypost-button-add-instance-loadings {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: rgb(255, 255, 255);
  animation: spin 1s infinite linear;
}
</style>
