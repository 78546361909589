import { createRouter, createWebHistory } from 'vue-router'
import RouterTop from '@/components/TopPages.vue'
import RouterShare from '@/components/ShareApp.vue'
import RouterTerms from '@/components/PrivacyPages.vue'
import RouterHowTo from '@/components/HowToUsePages.vue'

const routes = [
  {
    path: '/',
    name: 'top',
    component: RouterTop
  },
  {
    path: '/share',
    name: 'routerShare',
    component: RouterShare
  },
  {
    path: '/terms',
    name: 'routerTerms',
    component: RouterTerms
  },
  {
    path: '/howto',
    name: 'routerHowto',
    component: RouterHowTo
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return { top: 0 }
  } else {
    return { top: 0 }
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior
})

export default router
